<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="12">
        <div class="article-body clearfix mb-3">
          <!-- OneTrust Cookies List start -->
          <div id="ot-sdk-cookie-policy"></div>
          <!-- OneTrust Cookies List end -->
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'cookie-overview',
  extends: Page
};
</script>
